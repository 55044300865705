import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 高德地图
// 高德离线地图
import VueAMap from 'vue-amap';
Vue.use(VueAMap);

VueAMap.initAMapApiLoader({
  // 高德key
  key: 'a7b52be6ae5b405e639554ddb88bf6e9', // 自己到官网申请，我随便写的
  // 插件集合 （插件按需引入）
  plugin: [
    'AMap.Geolocation', //定位空间，用来获取和展示用户主机所在的经纬度位置
    ' AMap.Autocomplete ', //输入提示插件
    ' AMap.PlaceSearch ', //POI搜索插件
    ' AMap.Scale ', //右下角缩略图插件，比例尺
    ' AMap.OverView ', //地图鹰眼插件
    ' AMap.ToolBar ', //地图工具条
    ' AMap.MapType ', //类别切换空间，实现默认图层与卫星图，实施交通层之间切换的控制
    ' AMap.PolyEditor ', //编辑 折线多边形
    ' AMap.CircleEditor ',
    "AMap.Geocoder" //地图编码
  ],
  v: '1.4.15', // 我也不知道为什么要写这个，不写项目会报错，而且我随便写的，跟我下载的版本对应不了
  uiVersion: '1.0.11' // ui版本号，也是需要写
})
import './utils/rem'
// const setHtmlFontSize = () => {
//   const htmlDom = document.getElementsByTagName('html')[0];
//   let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
//   if (htmlWidth >= 750) {
//     htmlWidth = 750;
//   }
//   if (htmlWidth <= 320) {
//     htmlWidth = 320;
//   }
//   htmlDom.style.fontSize = `${htmlWidth / 7.5}px`;
// };
// window.onresize = setHtmlFontSize;
// setHtmlFontSize();

import axios from 'axios'
import Axios from 'axios'
Vue.prototype.$axios = axios
if (store.state.token) {
  Axios.defaults.headers.common['Authentication-Token'] = store.state.token;
  Axios.defaults.headers.common['token'] = store.state.token;
}

//自定义时间过滤器
Vue.filter('dateFormat',function(originVal) {
  const dt = new Date(originVal)
  const y = dt.getFullYear()
  //月份不足两位用0补齐
  const m = (dt.getMonth() + 1 +'').padStart(2,'0')
  const d = (dt.getDay() + '').padStart(2,'0')
  const hh = (dt.getHours() + '').padStart(2,'0')
  const mm = (dt.getMinutes() + '').padStart(2,'0')
  const ss = (dt.getSeconds()+ '').padStart(2,'0')

  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})

Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')